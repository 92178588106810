<template>
    <v-card>
        <v-subheader>
            <v-icon left> {{ icon }}</v-icon>
            {{ title }}
        </v-subheader>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="items_per_page"
            class="elevation-1"
            @click:row="playerClick"
            v-bind:hide-default-footer="hide_footer"
        >
            <template v-slot:[`item.player_name`]="{ item }">
                <v-list-item-title>
                    <v-icon
                        dense
                        v-if="item.rank <= 3"
                        :class="$store.state.placeClasses[item.rank]"
                    >
                        mdi-star-circle
                    </v-icon>
                    {{ item.player_name }}
                </v-list-item-title>
            </template>
            <template v-slot:[`item.full_name`]="{ index, item }">
                <v-list-item-title>
                    <v-icon
                        dense
                        v-if="index + 1 <= 3"
                        :class="$store.state.placeClasses[index + 1]"
                    >
                        mdi-star-circle
                    </v-icon>
                    {{ item.full_name }}
                </v-list-item-title>
            </template>
            <template v-slot:[`item.guesses`]="{ item }">
                <v-chip
                :color="getColor(item.fail)"
                >
                {{ item.guesses }}
                </v-chip>
            </template>
            <template v-slot:[`item.streak`]="{ item }">
                <v-list-item-title v-if="item.streak >= 3">
                    <img v-if="item.streak >= 3" style="max-width: 20px; max-height: 20px" class="mr-3" src="/static/streak.png">
                    {{ item.streak }}
                </v-list-item-title>
            </template>
            <template v-slot:[`item.points`]="{ item }">
                <v-list-item-title>
                    {{ item.points }}
                </v-list-item-title>
            </template>
        </v-data-table>
    </v-card>
    
</template>

<script>

export default {
  
  props: {
    title: String,
    icon: String,
    items: [],
    headers: Array,
    items_per_page: Number,
    hide_footer: Boolean,
  },
  methods: {
    playerClick(row) {
        this.items.map((item) => {
            if (item == row) {
                if (row.player_username) {
                    this.$router.push(`/wordle/${row.player_username}`)
                } else if (item.player) {
                    this.$router.push(`/players/${item.player}`)
                } else if (item.id) {
                    this.$router.push(`/players/${item.id}`)
                }
            }
        })
    },
    getColor (fail) {
        if (fail) return 'red'
        else return 'green'
    },
  },
}

</script>
