export const ACTIVATION_BEGIN = 'ACTIVATION_BEGIN';
export const ACTIVATION_CLEAR = 'ACTIVATION_CLEAR';
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const PASSWORD_EMAIL_BEGIN = 'PASSWORD_EMAIL_BEGIN';
export const PASSWORD_EMAIL_CLEAR = 'PASSWORD_EMAIL_CLEAR';
export const PASSWORD_EMAIL_ERROR = 'PASSWORD_EMAIL_ERROR';
export const PASSWORD_EMAIL_SUCCESS = 'PASSWORD_EMAIL_SUCCESS';
export const PASSWORD_RESET_BEGIN = 'PASSWORD_RESET_BEGIN';
export const PASSWORD_RESET_CLEAR = 'PASSWORD_RESET_CLEAR';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const REGISTRATION_BEGIN = 'REGISTRATION_BEGIN';
export const REGISTRATION_CLEAR = 'REGISTRATION_CLEAR';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

export const SELECT_PLAYER = 'SELECT_PLAYER';
export const PLAYER_NOT_FOUND = 'SELECT_PLAYER';

export const PLAYER_ALL_BEGIN = 'PLAYER_ALL_BEGIN'
export const PLAYER_ALL_ERROR = 'PLAYER_ALL_ERROR'
export const PLAYER_ALL_SUCCESS = 'PLAYER_ALL_SUCCESS'

export const PLAYER_GUESS_DISTRIBUTION_BEGIN = 'PLAYER_GUESS_DISTRIBUTION_BEGIN'
export const PLAYER_GUESS_DISTRIBUTION_ERROR = 'PLAYER_GUESS_DISTRIBUTION_ERROR'
export const PLAYER_GUESS_DISTRIBUTION_SUCCESS = 'PLAYER_GUESS_DISTRIBUTION_SUCCESS'

export const PLAYER_WORDLES_BEGIN = 'PLAYER_WORDLES_BEGIN'
export const PLAYER_WORDLES_ERROR = 'PLAYER_WORDLES_ERROR'
export const PLAYER_WORDLES_SUCCESS = 'PLAYER_WORDLES_SUCCESS'

export const PLAYER_WORDLE_STATS_BEGIN = 'PLAYER_WORDLE_STATS_BEGIN'
export const PLAYER_WORDLE_STATS_ERROR = 'PLAYER_WORDLE_STATS_ERROR'
export const PLAYER_WORDLE_STATS_SUCCESS = 'PLAYER_WORDLE_STATS_SUCCESS'

export const WORDLE_STATS_BEGIN = 'WORDLE_STATS_BEGIN'
export const WORDLE_STATS_ERROR = 'WORDLE_STATS_ERROR'
export const WORDLE_STATS_SUCCESS = 'WORDLE_STATS_SUCCESS'

export const WORDLE_STATUS_BEGIN = 'WORDLE_STATUS_BEGIN';
export const WORDLE_STATUS_ERROR = 'WORDLE_STATUS_ERROR';
export const WORDLE_STATUS_SUCCESS = 'WORDLE_STATUS_SUCCESS';

export const WORDLE_GUESS_BEGIN = 'WORDLE_GUESS_BEGIN';
export const WORDLE_GUESS_ERROR = 'WORDLE_GUESS_ERROR';
export const WORDLE_GUESS_SUCCESS = 'WORDLE_GUESS_SUCCESS';
export const WORDLE_GUESS_RESPONDED = 'WORDLE_GUESS_RESPONDED';

export const WORDLE_TODAY_BEGIN = 'WORDLE_TODAY_BEGIN'
export const WORDLE_TODAY_ERROR = 'WORDLE_TODAY_ERROR'
export const WORDLE_TODAY_SUCCESS = 'WORDLE_TODAY_SUCCESS'

export const WORDLE_LEADERS_POINTS_BEGIN = 'WORDLE_LEADERS_POINTS_BEGIN';
export const WORDLE_LEADERS_POINTS_SUCCESS = 'WORDLE_LEADERS_POINTS_SUCCESS';
export const WORDLE_LEADERS_POINTS_ERROR = 'WORDLE_LEADERS_POINTS_ERROR';

export const WORDLE_SHAME_BEGIN = 'WORDLE_SHAME_BEGIN'
export const WORDLE_SHAME_ERROR = 'WORDLE_SHAME_ERROR'
export const WORDLE_SHAME_SUCCESS = 'WORDLE_SHAME_SUCCESS'

export const WORDLE_LEADERS_GUESSES_BEGIN = 'WORDLE_LEADERS_GUESSES_BEGIN'
export const WORDLE_LEADERS_GUESSES_ERROR = 'WORDLE_LEADERS_GUESSES_ERROR'
export const WORDLE_LEADERS_GUESSES_SUCCESS = 'WORDLE_LEADERS_GUESSES_SUCCESS'

export const WORDLE_LEADERS_TIME_BEGIN = 'WORDLE_LEADERS_TIME_BEGIN'
export const WORDLE_LEADERS_TIME_ERROR = 'WORDLE_LEADERS_TIME_ERROR'
export const WORDLE_LEADERS_TIME_SUCCESS = 'WORDLE_LEADERS_TIME_SUCCESS'


