<template>
    <WordleGame :username="username"/>
</template>

<script>
import axios from "axios";
import WordleGame from "../wordle/WordleGame";

export default {
  name: "WordleView",
  components: { WordleGame},
  props: ['username'],
  created() {
    console.log("WordleView created with username:", this.username); // Check if username is received
    this.$store.dispatch("fetchLeaderboard");
  },
};
</script>
