<template>
  <div id="lost-view">
    <h1>Page Not Found</h1>
    <h4>One of us is lost.</h4>
    <router-link to="/">Take me home.</router-link>
  </div>
</template>

<script>
export default {
  name: 'LostView',
};
</script>