var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-subheader',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.items_per_page,"hide-default-footer":_vm.hide_footer},on:{"click:row":_vm.playerClick},scopedSlots:_vm._u([{key:"item.player_name",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',[(item.rank <= 3)?_c('v-icon',{class:_vm.$store.state.placeClasses[item.rank],attrs:{"dense":""}},[_vm._v(" mdi-star-circle ")]):_vm._e(),_vm._v(" "+_vm._s(item.player_name)+" ")],1)]}},{key:"item.full_name",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-list-item-title',[(index + 1 <= 3)?_c('v-icon',{class:_vm.$store.state.placeClasses[index + 1],attrs:{"dense":""}},[_vm._v(" mdi-star-circle ")]):_vm._e(),_vm._v(" "+_vm._s(item.full_name)+" ")],1)]}},{key:"item.guesses",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.fail)}},[_vm._v(" "+_vm._s(item.guesses)+" ")])]}},{key:"item.streak",fn:function(ref){
var item = ref.item;
return [(item.streak >= 3)?_c('v-list-item-title',[(item.streak >= 3)?_c('img',{staticClass:"mr-3",staticStyle:{"max-width":"20px","max-height":"20px"},attrs:{"src":"/static/streak.png"}}):_vm._e(),_vm._v(" "+_vm._s(item.streak)+" ")]):_vm._e()]}},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',[_vm._v(" "+_vm._s(item.points)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }